import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
    LOGIN_USER,
    REGISTER_USER,
    LOGOUT_USER,
    FORGOT_PASSWORD,
    RESET_PASSWORD,
    SIGN_RECEPTOR,
    ON_COUNT,
    SIGN_ONE_SHOT
} from '../actions';

import {
    loginUserSuccess,
    loginUserError,
    registerUserSuccess,
    registerUserError,
    forgotPasswordSuccess,
    forgotPasswordError,
    resetPasswordSuccess,
    resetPasswordError,
    signReceptorSuccess,
    signReceptorError,
    onCountSuccess,
    onCountError,
    oneShotSuccess,
    oneShotError
} from './actions';
import {NotificationManager} from "../../components/common/react-notifications";

/*Metodos para Login*/

export function* watchLoginUser() {
    yield takeEvery(LOGIN_USER, loginWithUsernamePassword);
}

function* loginWithUsernamePassword({ payload }) {
    const { username , password, identificacion } = payload.user;
    const { history } = payload;
    try {
        const loginUser = yield call(loginWithUsernamePasswordAsync, username , password, identificacion);
        if (loginUser.user && loginUser.user.PASSWORD_CADUCADO_USUARIO===1) {
            yield put(loginUserError("Por favor cambie su contraseña"));
            setTimeout(() => {
                window.location.href = '/user/reset-password?token='+loginUser.idToken+'&usuario='+loginUser.user.ID_USUARIO;
            }, 3000);
        } else if (loginUser.result) {
            localStorage.setItem("user_id", JSON.stringify(loginUser.user));
            localStorage.setItem("idToken", loginUser.idToken);
            localStorage.setItem("servicios", JSON.stringify(loginUser.servicios));
            yield put(loginUserSuccess(loginUser.user));
            history.push('/app');
        } else {
            yield put(loginUserError(loginUser.message));
        }
    } catch (error) {
        console.log(error);
        yield put(loginUserError(error));
    }
}

const loginWithUsernamePasswordAsync = async(username , password, identificacion) => {
    return fetch(process.env.REACT_APP_URL_BACKEND+"/usuarios/ingresar", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            "username": username,
            "password": password,
            "identificacion": identificacion
        })
    })
        .then(authUser => authUser.json())
        .catch(error => error);
};


/*Metodos para Register*/

export function* watchRegisterUser() {
    yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

function* registerWithEmailPassword({ payload }) {
    const { history } = payload;
    try {
        const registerUser = yield call(registerWithEmailPasswordAsync, payload.user);
        if (registerUser.result) {
            yield put(registerUserSuccess(registerUser));
            NotificationManager.success(
                registerUser.message,
                "Registro Exitoso",
                12000,
                null,
                null,
                'filled'
            );
            history.push('/user/login')
        } else {
            yield put(registerUserError(registerUser.message));
        }
    } catch (error) {
        yield put(registerUserError(error));
    }
}

const registerWithEmailPasswordAsync = async (user) => {
    return fetch(process.env.REACT_APP_URL_BACKEND+"/usuarios/registrar", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(user)
    })
        .then(authUser => authUser.json())
        .catch(error => error);
};


/*Metodos para Logout*/

export function* watchLogoutUser() {
    yield takeEvery(LOGOUT_USER, logout);
}

function* logout({ payload }) {
    const { history } = payload
    try {
        yield call(logoutAsync, history);
    } catch (error) {
    }
}

const logoutAsync = async (history) => {
    localStorage.removeItem('user_id');
    localStorage.removeItem('idToken');
    localStorage.removeItem('servicios');
    history.push('/user/login')
}


/*Metodos para Recuperar Contraseña*/

export function* watchForgotPassword() {
    yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

function* forgotPassword({ payload }) {
    const { email } = payload.forgotUserMail;
    try {
        const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
        if (forgotPasswordStatus.result) {
            yield put(forgotPasswordSuccess("success"));
        } else {
            yield put(forgotPasswordError(forgotPasswordStatus.message));
        }
    } catch (error) {
        yield put(forgotPasswordError(error));

    }
}

const forgotPasswordAsync = async (email) => {
    return fetch(process.env.REACT_APP_URL_BACKEND+"/usuarios/password/email", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            "email": email
        })
    })
        .then(authUser => authUser.json())
        .catch(error => error);
}


/*Metodos para Resetear Contraseña*/

export function* watchResetPassword() {
    yield takeEvery(RESET_PASSWORD, resetPassword);
}

function* resetPassword({ payload }) {
    const { newPassword, resetPasswordCode, history } = payload;
    try {
        const resetPasswordStatus = yield call(resetPasswordAsync, resetPasswordCode, newPassword);
        if (resetPasswordStatus.result) {
            yield put(resetPasswordSuccess("success"));
            history.push('/user/login')
        } else {
            yield put(resetPasswordError(resetPasswordStatus.message));
        }
    } catch (error) {
        yield put(resetPasswordError(error));

    }
}

const resetPasswordAsync = async (resetPasswordCode, newPassword) => {
    return fetch(process.env.REACT_APP_URL_BACKEND+"/usuarios/password/resetear", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            "usuario": resetPasswordCode,
            "password": newPassword
        })
    })
        .then(authUser => authUser.json())
        .catch(error => error);
}

/*Metodos para Firmar Receptor*/

export function* watchSignReceptor() {
    yield takeEvery(SIGN_RECEPTOR, signReceptorEmail);
}

function* signReceptorEmail({ payload }) {
    const { history } = payload;
    try {
        const signReceptor = yield call(signReceptorEmailAsync, payload.user);
        if (signReceptor.result) {
            yield put(signReceptorSuccess(signReceptor));
            NotificationManager.success(
                signReceptor.message,
                "Documento Firmado",
                12000,
                null,
                null,
                'filled'
            );
            history.push('/')
        } else {
            yield put(signReceptorError(signReceptor.message));
        }
    } catch (error) {
        yield put(signReceptorError(error));
    }
}

const signReceptorEmailAsync = async (user) => {
    return fetch(process.env.REACT_APP_URL_BACKEND+"/contrato/firmar/documento/receptor", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer "+user.token
        },
        body: JSON.stringify(user)
    })
        .then(authUser => authUser.json())
        .catch(error => error);
};

/*Metodos para Activar Usuario*/

export function* watchOnCount() {
    yield takeEvery(ON_COUNT, onCountEmail);
}

function* onCountEmail({ payload }) {
    const { history } = payload;
    try {
        const onCount = yield call(onCountEmailAsync, payload.user);
        if (onCount.result) {
            yield put(onCountSuccess(onCount));
            NotificationManager.success(
                onCount.message,
                "Cuenta Activada",
                12000,
                null,
                null,
                'filled'
            );
            history.push('/user/login')
        } else {
            yield put(onCountError(onCount.message));
        }
    } catch (error) {
        yield put(onCountError(error));
    }
}

const onCountEmailAsync = async (user) => {
    return fetch(process.env.REACT_APP_URL_BACKEND+"/usuarios/activar/cuenta", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(user)
    })
        .then(authUser => authUser.json())
        .catch(error => error);
};

/*Metodos para Firmar Documentos Certificados*/

export function* watchSignOneShot() {
    yield takeEvery(SIGN_ONE_SHOT, signOneShot);
}

function* signOneShot({ payload }) {
    try {
        const signReceptor = yield call(signOneShotAsync, payload.user);
        if (signReceptor.result) {
            /*if(payload.user.modal())
                payload.user.modal();*/
            yield put(oneShotSuccess(signReceptor));
        } else {
            yield put(oneShotError(signReceptor.message));
        }
    } catch (error) {
        yield put(oneShotError(error));
    }
}

const signOneShotAsync = async (user) => {
    return fetch(process.env.REACT_APP_URL_BACKEND+"/contrato/finalizar/firma/documento/certificado", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer "+user.token
        },
        body: JSON.stringify(user)
    })
        .then(authUser => authUser.json())
        .catch(error => error);
};

export default function* rootSaga() {
    yield all([
        fork(watchLoginUser),
        fork(watchLogoutUser),
        fork(watchRegisterUser),
        fork(watchForgotPassword),
        fork(watchResetPassword),
        fork(watchSignReceptor),
        fork(watchOnCount),
        fork(watchSignOneShot),
    ]);
}